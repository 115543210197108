
export default function reducer(state = {
    messages: [],
    loaded: false
}, action) {
    switch (action.type) {

        case "CHAT_FETCH": {
            return {
                ...state,
                loaded: false
            };
        }

        case "CHAT_UPDATED": {
            return {
                ...state,
                messages: action.payload,
                loaded: true
            };
        }

        default: return state;

    }
}