
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Input, View, Text, Image } from 'view/factory';

import strings from 'assets/strings';
import colors from 'assets/colors';

import { updateCall, deleteCall } from 'model/firebase';
import { getThousands } from 'model/common/money';
import { defaultCall, setAsPurchase, setAsSale } from 'model/firebase/call';
import { notifyUpdate } from 'model/firebase/push';

const increaseIcon = require("assets/ic_increase_white.png");
const decreaseIcon = require("assets/ic_decrease_white.png");

class CallContainer extends React.Component {

    state = {
        currentCall: this.props.location.state ? this.props.location.state.call : {},
        call: Object.assign(defaultCall(), this.props.location.state ? this.props.location.state.call : {}),
        confirmDelete: false
    }

    componentDidMount() {
        if (!this.props.isAdmin) return this.props.history.push("/");
    }

    render() {
        return (
            <View>
                {this.renderOperationHeader()}
                {this.renderOperationLabel()}
                {this.renderValuesInput()}
                {this.renderButtons()}
            </View>
        );
    }

    renderOperationHeader() {
        return (
            <View row spaceBetween mTop={16} mBottom={16} mHorizontal={16}>

                <View
                    flex={0.45}
                    height={48}
                    radius={12}
                    center
                    shadow
                    color={this.state.call.isPurchase ? colors.callAccentPurchase : colors.gray}
                    onClick={() => this.setState({ call: setAsPurchase(this.state.call) })}
                >
                    <Text h1 bold color={this.state.call.isPurchase ? colors.darkestGray : colors.darkishGray}>{strings.trade_operation_purchase}</Text>
                </View>

                <View
                    flex={0.45}
                    height={48}
                    radius={12}
                    center
                    shadow
                    color={this.state.call.isSale ? colors.callAccentSale : colors.gray}
                    onClick={() => this.setState({ call: setAsSale(this.state.call) })}
                >
                    <Text h1 bold color={this.state.call.isSale ? colors.darkestGray : colors.darkishGray}>{strings.trade_operation_sale}</Text>
                </View>

            </View>
        );
    }

    renderOperationLabel() {
        return (
            <View center row mBottom={16}>

                <Text mRight={4} align={'center'} h2 color={colors.lightestGray}>{
                    this.state.call.isPurchase ?
                        strings.trade_edit_operation_purchase :
                        strings.trade_edit_operation_sale}
                </Text>

                <Text align={'center'} h2 color={colors.lightestGray}>{
                    this.state.call.isActive ?
                        strings.trade_edit_active_label :
                        this.state.call.isGain ? strings.trade_edit_gain_label :
                        this.state.call.isLoss ? strings.trade_edit_loss_label :
                        strings.trade_edit_inactive_label }
                </Text>

            </View>
        );
    }

    renderValuesInput() {
        return (
            <View>

                <View center mBottom={8}>

                    <Text mBottom={4} align={'center'} h2 color={colors.lightestGray}>{strings.trade_start}</Text>

                    <View row center>

                        <View touchable onClick={() => this.setState({ call: { ...this.state.call, start: this.state.call.start > 0 ? this.state.call.start - 5 : 0 } })}
                            width={36} height={36} center color={colors.input} radius={20} border={{ width: 2, color: colors.white }}>
                            <Image size={40} src={decreaseIcon} />
                        </View>

                        <View mHorizontal={16}>
                            <Input
                                style={{ textAlign: 'center' }}
                                type={'tel'}
                                maxLength={6}
                                value={`${this.state.call.start}`}
                                onFocus={() => this.setState({ call: { ...this.state.call, start: '' } })}
                                onChange={event => this.startChanged(event.target.value)}
                            />
                        </View>

                        <View touchable onClick={() => this.setState({ call: { ...this.state.call, start: this.state.call.start + 5 } })}
                            width={36} height={36} center color={colors.input} radius={20} border={{ width: 2, color: colors.white }}>
                            <Image size={40} src={increaseIcon} />
                        </View>

                    </View>

                </View>

                <View center mBottom={8}>

                    <Text mBottom={4} align={'center'} h2 color={colors.lightestGray}>{strings.trade_out}</Text>

                    <View row center>

                        <View touchable onClick={() => this.setState({ call: { ...this.state.call, out: this.state.call.out > 0 ? this.state.call.out - 5 : 0 } })}
                            width={36} height={36} center color={colors.input} radius={20} border={{ width: 2, color: colors.white }}>
                            <Image size={40} src={decreaseIcon} />
                        </View>

                        <View mHorizontal={16}>
                            <Input
                                style={{ textAlign: 'center' }}
                                type={'tel'}
                                maxLength={6}
                                value={`${this.state.call.out}`}
                                onFocus={() => this.setState({ call: { ...this.state.call, out: getThousands(this.state.call.out) } })}
                                onChange={event => this.outChanged(event.target.value)}
                            />
                        </View>

                        <View touchable onClick={() => this.setState({ call: { ...this.state.call, out: this.state.call.out + 5 } })}
                            width={36} height={36} center color={colors.input} radius={20} border={{ width: 2, color: colors.white }}>
                            <Image size={40} src={increaseIcon} />
                        </View>

                    </View>

                </View>

                <View center mBottom={16}>

                    <Text mBottom={4} align={'center'} h2 color={colors.lightestGray}>{strings.trade_stop}</Text>

                    <View row center>

                        <View touchable onClick={() => this.setState({ call: { ...this.state.call, stop: this.state.call.stop > 0 ? this.state.call.stop - 5 : 0 } })}
                            width={36} height={36} center color={colors.input} radius={20} border={{ width: 2, color: colors.white }}>
                            <Image size={40} src={decreaseIcon} />
                        </View>

                        <View mHorizontal={16}>
                            <Input
                                style={{ textAlign: 'center' }}
                                type={'tel'}
                                maxLength={6}
                                value={`${this.state.call.stop}`}
                                onFocus={() => this.setState({ call: { ...this.state.call, stop: getThousands(this.state.call.stop) } })}
                                onChange={event => this.stopChanged(event.target.value)}
                            />
                        </View>

                        <View touchable onClick={() => this.setState({ call: { ...this.state.call, stop: this.state.call.stop + 5 } })}
                            width={36} height={36} center color={colors.input} radius={20} border={{ width: 2, color: colors.white }}>
                            <Image size={40} src={increaseIcon} />
                        </View>

                    </View>

                </View>

            </View>
        );
    }

    renderButtons() {
        const isEdited = this.isEdited();
        const isValid = this.isValid();
        return (
            <View mTop={4} center width={'100%'} >

                <View touchable shadow center mBottom={8}
                    width={'90%'} height={48} radius={12}
                    color={isEdited && isValid ? colors.lightBlue : colors.gray}
                    onClick={() => isEdited && isValid ? this.updateCall(this.state.call) : null}
                >
                    <Text h1 bold color={isEdited && isValid ? colors.darkestGray : colors.darkishGray}>
                        {this.doneLabel(isEdited, isValid)}
                    </Text>
                </View>

                <View center row width={'90%'} mBottom={8}>

                    <View touchable shadow center
                        width={'30%'} height={48} radiusRight={12}
                        color={this.state.call.isLoss ? colors.selectedRed : colors.unselectedRed}
                        onClick={() => this.setAsLoss()}
                    >
                        <Text h1 bold color={colors.darkestGray}>{strings.trade_edit_loss}</Text>
                    </View>

                    <View touchable shadow center mHorizontal={8}
                        width={'40%'} height={48} radius={12}
                        color={this.state.call.isActive ? colors.selectedBlue : colors.unselectedBlue}
                        onClick={() => this.toggleActive()}
                    >
                        <Text h1 bold color={colors.darkestGray}>
                            {this.state.call.isActive ? strings.trade_edit_deactivate : strings.trade_edit_activate}
                        </Text>
                    </View>

                    <View touchable shadow center
                        width={'30%'} height={48} radiusLeft={12}
                        color={this.state.call.isGain ? colors.selectedGreen : colors.unselectedGreen}
                        onClick={() => this.setAsGain()}
                    >
                        <Text h1 bold color={colors.darkestGray}>{strings.trade_edit_gain}</Text>
                    </View>

                </View>

                <View touchable shadow center mBottom={8}
                    width={'90%'} height={48} radius={12}
                    color={colors.lightRed}
                    onClick={() => this.deleteCall(this.state.currentCall)}
                >
                    <Text h1 bold color={colors.red}>
                        {this.state.confirmDelete ? strings.trade_edit_delete_confirm : strings.trade_edit_delete}
                    </Text>
                </View>

            </View>
        );
    }

    startChanged(startInput) {

        const start = parseFloat(startInput);
        if (startInput && !start) return;
        if (!startInput && !start) return this.setState({ call: { ...this.state.call, start: '' } });

        if (startInput.length >= 5) {
            return this.setState({
                call: {
                    ...this.state.call,
                    start: start,
                    out: this.state.call.isPurchase ? start + 125 : start - 125,
                    stop: this.state.call.isPurchase ? start - 60 : start + 60,
                }
            });
        }

        this.setState({ call: { ...this.state.call, start: start } });

    }

    outChanged(input) {
        if (input && !parseFloat(input)) return;
        if (!input && !parseFloat(input)) return this.setState({ call: { ...this.state.call, out: '' } });
        this.setState({ call: { ...this.state.call, out: parseFloat(input) } });
    }

    stopChanged(input) {
        if (input && !parseFloat(input)) return;
        if (!input && !parseFloat(input)) return this.setState({ call: { ...this.state.call, stop: '' } });
        this.setState({ call: { ...this.state.call, stop: parseFloat(input) } });
    }

    doneLabel(isEdited, isValid) {
        if (!isValid) return strings.trade_edit_invalid;
        if (!isEdited) return strings.trade_edit_none;
        return strings.trade_edit_done;
    }

    updateCall(call) {
        updateCall(call);
        notifyUpdate(call, !!this.state.currentCall.operation);
        this.props.history.goBack();
    }

    deleteCall(call) {
        if (!this.state.confirmDelete) return this.setState({ confirmDelete: true });
        deleteCall(call);
        notifyUpdate(call, !!this.state.currentCall.operation, true);
        this.props.history.goBack();
    }

    toggleActive() {
        this.setState({
            call: { ...this.state.call, isActive: !this.state.call.isActive, isLoss: false, isGain: false }
        });
    }

    setAsGain() {
        this.setState({
            call: { ...this.state.call, isActive: false, isLoss: false, isGain: !this.state.call.isGain }
        });
    }

    setAsLoss() {
        this.setState({
            call: { ...this.state.call, isActive: false, isLoss: !this.state.call.isLoss, isGain: false }
        });
    }

    isEdited() {
        return this.state.currentCall.operation !== this.state.call.operation ||
            this.state.currentCall.isActive !== this.state.call.isActive ||
            this.state.currentCall.isGain !== this.state.call.isGain ||
            this.state.currentCall.isLoss !== this.state.call.isLoss ||
            this.state.currentCall.start !== this.state.call.start ||
            this.state.currentCall.out !== this.state.call.out ||
            this.state.currentCall.stop !== this.state.call.stop;
    }

    isValid() {

        if (this.state.call.isPurchase) {
            return this.state.call.out > this.state.call.start &&
                this.state.call.stop < this.state.call.start
        }

        return this.state.call.out < this.state.call.start &&
            this.state.call.stop > this.state.call.start

    }

}

function mapStateToProps(state) {
    return {
        isAdmin: state.user.isAdmin
    }
}

function mapDispatchToProps(dispatch) {
    return ({

    });
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CallContainer));