
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import { View, Image, Loading, Text } from 'view/factory';
import colors from 'assets/colors';
import strings from 'assets/strings';

const logo = require("assets/ic_logo.png")

class SplashContainer extends React.Component {
    
    componentWillReceiveProps(props) {
        if (!props.isUserLoading) {
            if (props.isUserValid) return this.props.history.push('/trade');
            return this.props.history.push('/login');
        }
    }

    render() {
        return (
            <View page center>
                <Image mBottom={24} src={logo} size={128} />
                <Loading color={colors.deltaGreen} />
                <Text mTop={24} h2 color={colors.deltaGreen}>{strings.loading_app}</Text>
            </View>
        );
    }

}

function mapStateToProps(state) {
    return {
        isUserValid: state.user.isValid,
        isUserLoading: state.user.isLoading
    }
}

function mapDispatchToProps(dispatch) {
    return ({

    });
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SplashContainer));