
export function tradeFetchAction() {
    return {
        type: 'TRADE_FETCH'
    }
}

export function tradeUpdatedAction(trade) {
    return {
        type: 'TRADE_UPDATED',
        payload: trade
    }
}

export function setCallReadAction(callId) {
    return {
        type: 'CALL_READ',
        payload: callId
    }
}