
import React from 'react';
import { connect } from 'react-redux';

import MessageList from '../components/MessageList';
import { View, Image, Input, Text } from '../factory';

import { monitorChat, sendMessage, removeMessage } from 'model/firebase'

import colors from 'assets/colors';
import strings from 'assets/strings';
const sendIcon = require("assets/ic_send_light_blue.png");

class ChatContainer extends React.Component {

    state = {
        message: '',
        selected: null
    }

    componentDidMount() {
        monitorChat();
    }
    
    componentDidUpdate() {
        if (this.endOfMessages) {
            this.endOfMessages.scrollIntoView({ behavior: "instant" });  
        }
    }

    render() {
        return (
            <View>
                {this.renderMessages()}
                {this.renderInput()}
            </View>
        );
    }

    renderEmpty() {
        return (
            <View page center>
                <Text margin={64} align={'center'} h2 color={colors.lightestGray}>
                    {this.props.loading ? strings.loading_chat : strings.empty_chat}
                </Text>
            </View>
        );
    }

    renderMessages() {
        if (!this.props.messages.length) return this.renderEmpty();
        return (
            <View flex width={'100%'}  mBottom={this.props.isAdmin ? 60 : 0} >
                <MessageList
                    isAdmin={this.props.isAdmin}
                    messages={this.props.messages}
                    selected={this.state.selected}
                    onSelected={message => this.setState({ message: message.text, selected: message.id })}
                    onRemove={messageId => this.removeMessage(messageId)}
                />
                <View ref={element => this.endOfMessages = element}/>
            </View>
        );
    }

    renderInput() {
        if (!this.props.isAdmin) return null;
        return (
            <View
                fixed row
                width={'100%'}
                bottom={60}
                color={colors.lightGray}
                pTop={8}
                pBottom={8}
                center
            >

                <View flex center mLeft={16}>
                    <Input
                        onKeyDown={event => event.key === 'Enter' ? this.sendMessage() : null}
                        value={this.state.message}
                        onChange={event => this.setState({ message: event.target.value })} />
                </View>

                <View  centerVertical pLeft={32} pRight={24} onClick={() => this.sendMessage()}>
                    <Image size={36} src={sendIcon} />
                </View>

            </View>
        );
    }

    sendMessage() {
        if (!this.state.message) return;
        sendMessage(this.state.selected, this.state.message);
        this.setState({ message: '', selected: null });
    }

    removeMessage(messageId) {
        removeMessage(messageId);
        this.setState({ message: '', selected: null });
    }

}

const mapStateToProps = state => {
    return {
        messages: state.chat.messages,
        loading: !state.chat.loaded,
        isAdmin: state.user.isAdmin && !state.user.viewAsUser,
    }
};

const mapDispatchToProps = dispatch => {
    return {}
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChatContainer);


