
export function chatFetchAction() {
    return {
        type: 'CHAT_FETCH'
    }
}

export function chatUpdatedAction(messages) {
    return {
        type: 'CHAT_UPDATED',
        payload: messages
    }
}