
import { database } from 'model/firebase/firebase';

import { parseCall, clearCall } from 'model/firebase/call';

import { store } from 'controller/Store';
import { tradeUpdatedAction, tradeFetchAction } from 'controller/actions/tradeAction';

export function monitorTrade() {
    store.dispatch(tradeFetchAction());
    database.ref('trade').on('value', snapshot => {
        const trade = snapshot.val() || {};
        const list = Object.keys(trade).map(key => parseCall(key, trade[key]));
        const sorted = list
            .sort((t1, t2) => t2.updated - t1.updated)
            .sort((t1, t2) => t1.isActive === t2.isActive ? 0 : t1.isActive ? -1 : 1);
        const firstInactive = sorted.find(call => !call.isActive);
        const firstInactiveIndex = sorted.indexOf(firstInactive);
        if (firstInactiveIndex !== -1) sorted.splice(firstInactiveIndex, 0, { isInactiveDivider: true });
        store.dispatch(tradeUpdatedAction(sorted));
    });
}

export function updateCall(call) {
    if (!call) return;
    if (!call.id) call.id = database.ref('trade').push().key;
    database.ref(`trade/${call.id}`).set(clearCall(call));
}

export function deleteCall(call) {
    if (!call) return;
    if (!call.id) return;
    database.ref(`trade/${call.id}`).remove();
}

