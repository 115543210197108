
export function setUserAction(user) {
    return {
        type: 'SET_USER',
        payload: user
    }
}

export function setViewAsUserAction(viewAsUser) {
    return {
        type: 'SET_VIEW_AS_USER',
        payload: viewAsUser
    }
}

export function setAdminActiveAction(active) {
    return {
        type: 'SET_ADMIN_ACTIVE',
        payload: active
    }
}
