
export default function reducer(state = {
    id: '',
    name: '',
    phone: '',
    email: '',
    isValid: false,
    isAdmin: false,
    isAdminActive: false,
    isLoading: true,
    viewAsUser: false
}, action) {
    switch (action.type) {

        case "SET_USER": {
            return {
                ...state,
                id: action.payload.id || '',
                name: action.payload.name || '',
                phone: action.payload.phone || '',
                email: action.payload.email || '',
                isValid: action.payload.isValid || false,
                isAdmin: action.payload.isAdmin || false,
                isLoading: false
            };
        }

        case "SET_VIEW_AS_USER": {
            return {
                ...state,
                viewAsUser: action.payload
            };
        }

        case "SET_ADMIN_ACTIVE": {
            return {
                ...state,
                isAdminActive: action.payload
            };
        }

        default: return state;

    }
}