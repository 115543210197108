
import React from 'react';

import styled from 'styled-components';
import colors from '../../assets/colors';
import { fullWidth, fullHeight } from 'assets/dimensions';

export const View = styled.div`
    
    ${props => props.hide ? 'display: none' : 'display: flex'}
    max-width: 800px;

    ${props => props.touchable ? '&:active { opacity: 0.3 };' : null}
    ${props => props.hidden ? 'overflow: hidden;' : null}

    ${props => props.page ? `width: ${fullWidth}px; height: ${fullHeight}px` : null}
    ${props => props.fullscreen ? `width: 100vw; height: 100vh` : null}
    ${props => props.width ? typeof props.width === "number" ? `width: ${props.width}px` : `width: ${props.width}` : null}
    ${props => props.height ? typeof props.height === "number" ? `height: ${props.height}px` : `height: ${props.height}` : null}
    ${props => props.minWidth ? typeof props.minWidth === "number" ? `min-width: ${props.minWidth}px` : `min-width: ${props.minWidth}` : null}
    ${props => props.minHeight ? typeof props.minHeight === "number" ? `min-height: ${props.minHeight}px` : `min-height: ${props.minHeight}` : null}
    
    flex-direction: ${props => props.row ? "row" : "column"};
    ${props => props.flex ? `flex: ${parseFloat(props.flex) ? props.flex : 1}` : null};
    
    ${props => props.center ? "justify-content: center; align-items: center;" : null};
    ${props => props.centerHorizontal ? props.row ? "justify-content: center;" : "align-items: center;" : null};
    ${props => props.centerVertical ? props.row ? "align-items: center;" : "justify-content: center;" : null};
    ${props => props.selfCenter ? `align-self: center;` : null};

    ${props => props.alignStart ? `align-items: flex-start;` : null};
    ${props => props.alignEnd ? `align-items: flex-end;` : null};

    ${props => props.padding ? `padding: ${props.padding}px` : null};
    ${props => props.pVertical ? `padding-top: ${props.pVertical}px; padding-bottom: ${props.pVertical}px` : null};
    ${props => props.pHorizontal ? `padding-left: ${props.pHorizontal}px; padding-right: ${props.pHorizontal}px` : null};
    ${props => props.pTop ? `padding-top: ${props.pTop}px` : null};
    ${props => props.pBottom ? `padding-bottom: ${props.pBottom}px` : null};
    ${props => props.pLeft ? `padding-left: ${props.pLeft}px` : null};
    ${props => props.pRight ? `padding-right: ${props.pRight}px` : null};

    ${props => props.margin ? `margin: ${props.margin}px` : null};
    ${props => props.mVertical ? `margin-top: ${props.mVertical}px; margin-bottom: ${props.mVertical}px` : null};
    ${props => props.mHorizontal ? `margin-left: ${props.mHorizontal}px; margin-right: ${props.mHorizontal}px` : null};
    ${props => props.mTop ? `margin-top: ${props.mTop}px` : null};
    ${props => props.mBottom ? `margin-bottom: ${props.mBottom}px` : null};
    ${props => props.mLeft ? `margin-left: ${props.mLeft}px` : null};
    ${props => props.mRight ? `margin-right: ${props.mRight}px` : null};
    
    ${props => props.spaceBetween ? 'justify-content: space-between' : null}
    ${props => props.spaceAround ? 'justify-content: space-around' : null}

    ${props => props.color ? `background-color: ${props.color}` : null};
    ${props => props.circle ? `width: ${props.circle}px; height: ${props.circle}px; border-radius: ${props.circle}px` : null};
    ${props => props.radius ? `border-radius: ${props.radius}px` : null};
    ${props => props.radiusLeft ? `border-top-left-radius: ${props.radiusLeft}px; border-bottom-left-radius: ${props.radiusLeft}px;` : null};
    ${props => props.radiusRight ? `border-top-right-radius: ${props.radiusRight}px; border-bottom-right-radius: ${props.radiusRight}px;` : null};
    ${props => props.border ? `border-style: ${props.border.style || "solid"}; border-width: ${props.border.width}px; border-color: ${props.border.color};` : null};
    ${props => props.borderLeft ? `border-left-style: ${props.borderLeft.style || "solid"}; border-left-width: ${props.borderLeft.width}px; border-left-color: ${props.borderLeft.color};` : null};

    ${props => props.absolute ? `position: absolute` : null}
    ${props => props.fixed ? `position: fixed` : null}
    ${props => props.left ? `left: ${parseFloat(props.left) ? props.left : 0}px` : null}
    ${props => props.right ? `right: ${parseFloat(props.right) ? props.right : 0}px` : null}
    ${props => props.top ? `top: ${parseFloat(props.top) ? props.top : 0}px` : null}
    ${props => props.bottom ? `bottom: ${parseFloat(props.bottom) ? props.bottom : 0}px` : null}

    ${props => props.shadow ? `
        box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.3);
        transition: 0.3s;
    ` : null};

`;

export const Image = styled.img`
    ${props => props.size ? `width: ${props.size}px` : null}
    ${props => props.size ? `height: ${props.size}px` : null}
    ${props => props.width ? `width: ${props.width}px` : null}
    ${props => props.height ? `height: ${props.height}px` : null}
`;

export const Text = styled.p`

    margin: 0;
    font-family: 'Roboto';

    ${props => props.flex ? `flex: ${parseFloat(props.flex) ? props.flex : 1}` : null};

    ${props => props.padding ? `padding: ${props.padding}px` : null};
    ${props => props.pVertical ? `padding-top: ${props.pVertical}px; padding-bottom: ${props.pVertical}px` : null};
    ${props => props.pHorizontal ? `padding-left: ${props.pHorizontal}px; padding-right: ${props.pHorizontal}px` : null};
    ${props => props.pTop ? `padding-top: ${props.pTop}px` : null};
    ${props => props.pBottom ? `padding-bottom: ${props.pBottom}px` : null};
    ${props => props.pLeft ? `padding-left: ${props.pLeft}px` : null};
    ${props => props.pRight ? `padding-right: ${props.pRight}px` : null};

    ${props => props.margin ? `margin: ${props.margin}px` : null};
    ${props => props.mVertical ? `margin-top: ${props.mVertical}px; margin-bottom: ${props.mVertical}px` : null};
    ${props => props.mHorizontal ? `margin-left: ${props.mHorizontal}px; margin-right: ${props.mHorizontal}px` : null};
    ${props => props.mTop ? `margin-top: ${props.mTop}px` : null};
    ${props => props.mBottom ? `margin-bottom: ${props.mBottom}px` : null};
    ${props => props.mLeft ? `margin-left: ${props.mLeft}px` : null};
    ${props => props.mRight ? `margin-right: ${props.mRight}px` : null};
    
    ${props => props.h1 ? `font-size: 18px; color: ${colors.darkBlue}` : null};
    ${props => props.h2 ? `font-size: 16px; color: ${colors.darkBlue}` : null};
    ${props => props.body ? `font-size: 14px; color: ${colors.darkGray}` : null};
    ${props => props.caption ? `font-size: 12px; color: ${colors.darkGray}` : null};
    ${props => props.tiny ? `font-size: 10px; color: ${colors.darkGray}` : null};
    
    ${props => props.color ? `color: ${props.color}` : null};

    ${ props => props.weight ? `font-weight: ${props.weight}` : "font-weight: 400"}
    ${ props => props.bold ? `font-weight: 700` : null}
    ${ props => props.align ? `text-align: ${props.align}` : "text-align: left"}

`;

export const Input = styled.input`

    margin: 0px;
    padding-left: 8px;
    font-family: 'Roboto';
    font-size: 16px;

    align-self: center;
    width: 100%;
    height: 42px;
    border-radius: 8px;
    position: relative;
    background-color: rgba(255,255,255,0.75);
    transition: 0.3s all;

`;

export const Loading = (props) => (
    <svg width="40px" height="40px" enableBackground="new 0 0 50 50" version="1.1" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
        <path fill={props.color} d="m43.935 25.145c0-10.318-8.364-18.683-18.683-18.683-10.318 0-18.683 8.365-18.683 18.683h4.068c0-8.071 6.543-14.615 14.615-14.615s14.615 6.543 14.615 14.615h4.068z">
            <animateTransform attributeName="transform" attributeType="xml" dur="0.6s" from="0 25 25" repeatCount="indefinite" to="360 25 25" type="rotate" />
        </path>
    </svg>
)