
import firebase from 'model/firebase/firebase';
export * from 'model/firebase/chat';
export * from 'model/firebase/trade';
export * from 'model/firebase/push';

export function initializeFirebase() {
    firebase.database().goOnline();
}

