
export function formatPhone(phone, previous) {

    if (!phone) return null;
    if (phone.startsWith("+55")) phone = phone.slice(3, phone.length);

    const rawPhone = clearFormatting(phone);
    if (rawPhone.length === 11) return formatCellPhone(rawPhone);
    if (rawPhone.length === 10) return formatHomePhone(rawPhone);
    if (rawPhone.length > 11) return previous;
    if ((previous ? previous.length : 0) > phone.length) return phone;

    let finalphone = rawPhone;

    if (rawPhone.length < 10) {
        if (rawPhone.length >= 1) finalphone = "(" + finalphone;
        if (rawPhone.length >= 2) finalphone = [finalphone.slice(0, 3), ') ', finalphone.slice(3)].join('');
        if (rawPhone.length >= 6) finalphone = [finalphone.slice(0, 9), '-', finalphone.slice(9)].join('');
    }

    return finalphone;

}

function formatCellPhone(phone) {
    return phone.replace(/(\d{2})(\d{5})(\d{4})/g, "($1) $2-$3");
}

function formatHomePhone(phone) {
    return phone.replace(/(\d{2})(\d{4})(\d{4})/g, "($1) $2-$3");
}

export function clearFormatting(phone) {
    if (!phone) return "";
    return phone.replace(/(\(|\)|-| )/g, "");
}