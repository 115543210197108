
import moment from 'moment';
import { toPointsLabelWithoutPts } from "model/common/money";
import strings from "assets/strings";

export function parseCall(key, rawCall) {

    const call = {};

    call.id = key;

    call.operation = rawCall.operation || "purchase";
    call.isActive = rawCall.isActive || false;
    call.isGain = rawCall.isGain || false;
    call.isLoss = rawCall.isLoss || false;
    call.start = rawCall.start || 0;
    call.out = rawCall.out || 0;
    call.stop = rawCall.stop || 0;
    call.created = rawCall.created || 0;
    call.updated = rawCall.updated || 0;

    call.isPurchase = call.operation === "purchase";
    call.isSale = call.operation === "sale";
    call.operationLabel = call.isPurchase ? strings.trade_operation_purchase : strings.trade_operation_sale;

    call.startLabel = toPointsLabelWithoutPts(call.start);
    call.outLabel = toPointsLabelWithoutPts(call.out);
    call.stopLabel = toPointsLabelWithoutPts(call.stop);

    call.outDeltaValue = call.start - call.out;
    call.stopDeltaValue = call.start - call.stop;
    call.outDeltaLabel = strings.trade_delta_up(Math.abs(call.outDeltaValue));
    call.stopDeltaLabel = strings.trade_delta_down(Math.abs(call.stopDeltaValue));

    const currentDate = moment();
    const updatedDate = moment(call.updated);
    const timestamp = updatedDate.format("hh:mm");
    const howLongAgoHours = currentDate.diff(updatedDate, 'hours');
    const howLongAgoMinutes = currentDate.diff(updatedDate, 'minutes');
    if (howLongAgoHours >= 48) call.updatedLabel = strings.date_diff_over_day;
    else if (howLongAgoMinutes <= 1) call.updatedLabel = strings.date_diff_less_minute;
    else if (howLongAgoMinutes < 60) call.updatedLabel = strings.date_diff_minutes(timestamp, howLongAgoMinutes);
    else if (howLongAgoHours === 1) call.updatedLabel = strings.date_diff_hour(timestamp);
    else call.updatedLabel = strings.date_diff_hours(timestamp, howLongAgoHours);

    return call;

}

export function clearCall(call) {
    const cleanCall = {};
    cleanCall.operation = call.operation;
    cleanCall.isActive = call.isActive;
    cleanCall.isGain = call.isGain;
    cleanCall.isLoss = call.isLoss;
    cleanCall.start = call.start;
    cleanCall.out = call.out;
    cleanCall.stop = call.stop;
    cleanCall.created = call.created || moment.now();
    cleanCall.updated = moment.now();
    return cleanCall;
}

export function defaultCall() {
    const call = {};
    call.operation = "purchase";
    call.isActive = true;
    call.isGain = false;
    call.isLoss = false;
    call.start = 0;
    call.out = 0;
    call.stop = 0;
    call.created = 0;
    call.updated = 0;
    return parseCall(null, call);
}

export function setAsSale(call) {
    return {
        ...call,
        operation: "sale",
        operationLabel: strings.trade_operation_sale,
        isSale: true,
        isPurchase: false,
        out: call.out > call.start ? call.stop : call.out,
        stop: call.stop < call.start ? call.out : call.stop
    }
}

export function setAsPurchase(call) {
    return {
        ...call,
        operation: "purchase",
        operationLabel: strings.trade_operation_purchase,
        isSale: false,
        isPurchase: true,
        out: call.out < call.start ? call.stop : call.out,
        stop: call.stop > call.start ? call.out : call.stop
    }
}
