
import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyB-_vk0flARVQzhymRJz2fowpH2BLu4fL8",
    authDomain: "scalp-webjump.firebaseapp.com",
    databaseURL: "https://scalp-webjump.firebaseio.com",
    projectId: "scalp-webjump",
    storageBucket: "",
    messagingSenderId: "144563710054",
    appId: "1:144563710054:web:efaf14105ac46a67"
  };

const app = firebase.initializeApp(firebaseConfig);

export const database = app.database();
export const auth = firebase.auth(app);
export const RecaptchaVerifier = firebase.auth.RecaptchaVerifier;

export default app;