
const strings = {

    cancel: "Cancelar",
    confirm: "Confirmar",

    title_trade: "Trade",
    title_chat: "Notificações",
    title_menu: "Minha Conta",

    empty_trade: "Nenhuma Call encontrada.\nTente novamente mais tarde.",
    empty_chat: "Nenhuma Mensagem encontrada.\nTente novamente mais tarde.",

    loading_trade: "Buscando Calls...",
    loading_chat: "Buscando Mensagens...",
    loading_app: "Preparando Aplicativo...",

    trade_operation_purchase: "Compra",
    trade_operation_sale: "Venda",
    trade_stop: "Stop",
    trade_start: "Entrada",
    trade_out: "Saída",
    trade_new: "NEW",
    trade_inactive: "INATIVA",
    trade_gain: "GAIN",
    trade_loss: "LOSS",
    trade_inactive_divider: "Previsões Finalizadas",

    trade_total: total => `${total > 0 ? '+' : ''}${total}pts`,
    trade_result_current: `Saldo Atual`,
    trade_result_pending: `Saldo Pendente`,

    trade_edit_done: "Confirmar",
    trade_edit_none: "Sem Alterações",
    trade_edit_invalid: "Operação Inválida",
    trade_edit_delete: "Remover",
    trade_edit_delete_confirm: "Confirmar Remoção",
    trade_edit_deactivate: "Ativa",
    trade_edit_activate: "Inativa",
    trade_edit_gain: "Gain",
    trade_edit_loss: "Loss",

    trade_edit_operation_purchase: "Operação de Compra",
    trade_edit_operation_sale: "Operação de Venda",
    trade_edit_active_label: "(Ativa)",
    trade_edit_inactive_label: "(Inativa)",
    trade_edit_gain_label: "(Gain)",
    trade_edit_loss_label: "(Loss)",

    trade_delta_up: delta => `+${delta}pts`,
    trade_delta_down: delta => `-${delta}pts`,

    date_diff_over_day: "antiga",
    date_diff_days: days => `há ${days} dias`,
    date_diff_hours: (timestamp, hours) => `${timestamp} (há ${hours} horas)`,
    date_diff_hour: timestamp => `${timestamp} (há 1 hora)`,
    date_diff_minutes: (timestamp, minutes) => `${timestamp} (há ${minutes} minutos)`,
    date_diff_less_minute: "agora",

    chat_sent: when => `enviado ${when}`,
    chat_placeholder: "Enviar Mensagem",

    menu_view_as_user: "Visualizar como Usuário",
    admin_online: "Existem analistas online no momento.",
    admin: "Administrador",
    version: version => `Versão ${version}`,

    auth_title: "Para acessar o sistema é necessário confirmar sua identidade.",

    auth_recaptcha_title: "1. Confirme que não é um robô.",
    auth_recaptcha_loading: "Aguardando Validação...",
    auth_recaptcha_hint: "Será necessário completar um ReCaptcha",

    auth_phone_title: "2. Informe seu celular.",
    auth_phone_placeholder: "Celular",
    auth_phone_action: "Enviar",
    auth_phone_action_loading: "Enviando",
    auth_phone_hint: "Será necessário informar o código enviado",

    auth_code_title: "3. Informe o código enviado.",
    auth_code_action: "Validar",
    auth_code_action_loading: "Validando",
    auth_code_hint: phone => `Código enviado para ${phone}`,
    auth_code_error: "Código Inválido",

    auth_info_title: "4. Complete seu cadastro.",
    auth_info_name_placeholder: "Nome",
    auth_info_email_placeholder: "E-mail",
    auth_info_action: "Informar",
    auth_info_action_loading: "Enviando",
    auth_info_hint: "Seu acesso ficará pendente aprovação",
    
    auth_pending_title: "Acesso pendente autorização.",
    auth_pending_hint: "Seu registro está sendo analisado, volte mais tarde.",

}

export default strings;