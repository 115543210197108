
export default function reducer(state = {
    calls: [],
    loaded: false
}, action) {
    switch (action.type) {

        case "TRADE_FETCH": {
            return {
                ...state,
                loaded: false
            };
        }

        case "TRADE_UPDATED": {
            return {
                ...state,
                calls: action.payload.map(call => flagAsNew(call, state.calls)),
                loaded: true
            };
        }

        case "CALL_READ": {
            const calls = JSON.parse(JSON.stringify(state.calls));
            const readCall = calls.find(call => call.id === action.payload);
            if (!readCall) return state;
            readCall.isNew = false;
            return {
                ...state,
                calls: calls
            };
        }

        default: return state;

    }
}

function flagAsNew(call, calls) {
    const previousCall = calls.find(previousCall => previousCall.id === call.id) || {};
    const updatedLocal = previousCall.updatedLocal || 0;
    call.updatedLocal = Date.now();
    call.isNew = updatedLocal < call.updated;
    return call;
}