
import React from 'react';
import { withRouter } from "react-router";
import { View, Text, Image } from 'view/factory';

import strings from 'assets/strings';
import { fullWidth } from 'assets/dimensions';
import colors from 'assets/colors';

const chatIcon = require('assets/ic_chat_white.png');
const tradeIcon = require('assets/ic_trade_white.png');
const menuIcon = require('assets/ic_account_white.png');

class BottomBar extends React.Component {

    render() {
        const chatActive = this.props.location.pathname === "/chat";
        const tradeActive = this.props.location.pathname === "/trade";
        const menuActive = this.props.location.pathname === "/menu";
        return (
            <View
                row
                width={fullWidth}
                height={60}
                color={colors.darkestBlue}
                fixed
                center
                bottom
            >

                <View
                    pVertical={8}
                    width={'33%'}
                    center
                    color={chatActive ? colors.lightestBlue : colors.darkestBlue}
                    onClick={() => this.props.history.push("/chat")}
                >
                    <Image
                        size={36}
                        src={chatIcon} />
                    <Text mTop={4} h2 bold color={colors.white} caption>{strings.title_chat}</Text>
                </View>

                <View
                    pVertical={8}
                    width={'33%'}
                    center
                    color={tradeActive ? colors.lightestBlue : colors.darkestBlue}
                    onClick={() => this.props.history.push("/trade")}
                >
                    <Image
                        size={36}
                        src={tradeIcon} />
                    <Text mTop={4} h2 bold color={colors.white} caption>{strings.title_trade}</Text>
                </View>

                <View
                    pVertical={8}
                    width={'33%'}
                    center
                    color={menuActive ? colors.lightestBlue : colors.darkestBlue}
                    onClick={() => this.props.history.push("/menu")}
                >
                    <Image
                        size={36}
                        src={menuIcon} />
                    <Text mTop={4} h2 bold color={colors.white} caption>{strings.title_menu}</Text>
                </View>

            </View>
        );
    }

    isActive(component, screen) {
        const active = component.props.navigation.state.routeName === screen;
        return {
            backgroundColor: active ? colors.blue_selected : colors.blue
        }
    }

}

export default withRouter(BottomBar);