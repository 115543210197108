
import React from 'react';

import { View, Text, Image } from 'view/factory';
import colors from 'assets/colors';
const deleteIcon = require("assets/ic_remove_dark_blue.png");

export default class MessageList extends React.Component {

    render() {
        return (
            <View flex>
                <View flex margin={12}>
                    {this.props.messages.map(message => this.renderMessage(message, this.props.selected))}
                </View>
            </View>
        );
    }

    renderMessage(message, selected) {
        if (this.props.isAdmin) return this.renderMessageAdmin(message, selected);
        return (
            <View flex color={colors.lightestGray} shadow padding={16} mBottom={4} key={message.id}>
                <Text mBottom={8}>{message.text}</Text>
                <Text align={'right'} tiny>{message.createdLabel}</Text>
            </View>
        );
    }

    renderMessageAdmin(message, selected) {
        return (
            <View flex row centerVertical key={message.id}>

                <View
                    color={colors.white}
                    flex
                    shadow
                    padding={12}
                    mRight={message.id !== selected ? 0 : 8}
                    mBottom={4}
                    onClick={() => this.props.onSelected(message)}
                >
                    <Text mBottom={8}>{message.text}</Text>
                    <Text align={'right'} tiny>{message.createdLabel}</Text>
                </View>

                {message.id !== selected ? null :
                    (
                        <View center color={colors.white} circle={42} mBottom={4} onClick={() => this.props.onRemove(message.id)}>
                            <Image size={36} src={deleteIcon} />
                        </View>
                    )
                }

            </View>
        );
    }

}

MessageList.defaultProps = {
    messages: {},
    selected: null,
    isAdmin: false
}