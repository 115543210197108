
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { store } from './controller/Store';
import { initializeFirebase } from 'model/firebase';
import { monitorUser, checkAdminActive } from 'model/firebase/user';
import { requestPermission } from 'model/firebase/push';

import Splash from './view/containers/Splash';
import Login from './view/containers/Login';
import Main from './view/containers/Main';
import Trade from './view/containers/Trade';
import Call from './view/containers/Call';
import Chat from './view/containers/Chat';
import Menu from './view/containers/Menu';

class App extends React.Component {

  componentDidMount() {
    initializeFirebase();
    monitorUser();
    checkAdminActive();
    requestPermission();
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path='/' component={() => <Splash />} />
            <Route exact path='/login' component={() => <Login />} />
            <Route exact path='/trade' component={() => <Main screen={<Trade />} />} />
            <Route exact path='/call' component={() => <Main screen={<Call />} />} />
            <Route exact path='/chat' component={() => <Main screen={<Chat />} />} />
            <Route exact path='/menu' component={() => <Main screen={<Menu />} />} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
