
/**
 * Receives an Array of Calls.
 * Returns an Object with:
 * - total: Delta of points from Calls with Status Gain and Loss.
 * - isPositive: If Total results in Gain.
 * - isNegative: If Total results in Loss.
 * - totalGain: Sum of points from Calls with Status Gain.
 * - totalLoss: Sum of points from Calls with Status Loss.
 * - totalGainPending: Sum of Possible Gain from Calls with Status Pending.
 * - totalLossPending: Sum of Possible Loss from Calls with Status Pending.
 */
export function getCallsTotals(calls) {
    const totalGain = calls.filter(call => call.isGain).map(getDeltaGain).reduce((p, c) => p + c, 0);
    const totalLoss = calls.filter(call => call.isLoss).map(getDeltaLoss).reduce((p, c) => p + c, 0);
    const totalGainPending = calls.filter(call => call.isActive).map(getDeltaGain).reduce((p, c) => p + c, 0);
    const totalLossPending = calls.filter(call => call.isActive).map(getDeltaLoss).reduce((p, c) => p + c, 0);
    const total = totalGain + totalLoss;
    const isPositive = total >= 0;
    const isNegative = total < 0;
    return {
        total: total,
        isPositive: isPositive,
        isNegative: isNegative,
        totalGain: totalGain,
        totalLoss: totalLoss,
        totalGainPending: totalGainPending,
        totalLossPending: totalLossPending,
    };
}

/**
 * Receives a Call.
 * Returns the Delta for its Gain Result.
 */
function getDeltaGain(call) {
    if (call.isSale) return call.start - call.out;
    return call.out - call.start;
}

/**
 * Receives a Call.
 * Returns the Delta for its Loss Result.
 */
function getDeltaLoss(call) {
    if (call.isSale) return call.start - call.stop;
    return call.stop - call.start;
}