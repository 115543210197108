
import app, { auth, RecaptchaVerifier } from 'model/firebase/firebase';

export function showReCaptcha(recaptchaContainer) {
    return new Promise(resolve => {

        auth.languageCode = 'pt-br';

        window.recaptchaVerifier = new RecaptchaVerifier(recaptchaContainer, {
            size: 'normal',
            callback: response => resolve(response)
        }, app);

        window.recaptchaVerifier.render().then(widgetId => {
            window.recaptchaWidgetId = widgetId;
        });

    });
}

export function sendSMS(phone) {
    return new Promise((resolve, reject) => {
        const appVerifier = window.recaptchaVerifier;
        auth.signInWithPhoneNumber(phone, appVerifier)
            .then(confirmationResult => {
                window.confirmationResult = confirmationResult;
                resolve(confirmationResult);
            }).catch(function (error) {
                console.error(error);
                reject(error);
            });
    });
}
export function validateCode(code) {
    return new Promise((resolve, reject) => {
        const confirmationResult = window.confirmationResult;
        confirmationResult.confirm(code)
            .then(result => {
                resolve(result.user);
            })
            .catch(function (error) {
                console.error(error);
                reject(error);
            });
    });
}