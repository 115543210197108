
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import { View } from 'view/factory';
import BottomBar from 'view/components/BottomBar';
import { setLastAdminActive } from 'model/firebase/user';
import { monitorCalls } from 'model/firebase/push';

class MainContainer extends React.Component {

    componentDidMount() {
        if (!this.props.isUserValid) return this.props.history.replace('/login');
        if (this.props.isAdmin) setLastAdminActive();
        monitorCalls(this.props.id, this.props.isAdmin);
    }

    render() {
        return (
            <View>
                <View mBottom={60}>{this.props.screen}</View>
                <BottomBar />
            </View>
        );
    }

}

function mapStateToProps(state) {
    return {
        id: state.user.id,
        isUserValid: state.user.isValid,
        isAdmin: state.user.isAdmin,
    }
}

function mapDispatchToProps(dispatch) {
    return ({

    });
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainContainer));