
import React from 'react';
import { connect } from 'react-redux';
import { View, Text } from 'view/factory';

import Toggle from 'react-toggle'
import "react-toggle/style.css";

import { setViewAsUserAction } from 'controller/actions/userAction';
import { formatPhone } from 'model/common/phone';
import { VERSION } from 'model/common/constants';

import colors from 'assets/colors';
import strings from 'assets/strings';

class MenuContainer extends React.Component {

    render() {
        return (
            <View flex padding={16}>
                {this.userInfo()}
                {this.versionInfo()}
                {this.viewAsUserToggle()}
            </View>
        );
    }

    userInfo() {
        return (
            <View border={{ width: 1, color: colors.darkGray }} shadow radius={4} padding={8} mBottom={16}>
                <View row spaceBetween center mBottom={4}>
                    <Text h2 color={colors.lightGray} bold>{this.props.name}</Text>
                    {this.props.isAdmin ? <Text body color={colors.lightGray}>{strings.admin}</Text> : null}
                </View>
                <Text body color={colors.lightGray} mBottom={4}>{this.props.email}</Text>
                <Text body color={colors.lightGray}>{formatPhone(this.props.phone)}</Text>
            </View>
        );
    }

    versionInfo() {
        return(
            <View mHorizontal={8}>
                <Text h2 color={colors.lightGray} bold>{strings.version(VERSION)}</Text>
            </View>
        );
    }

    viewAsUserToggle() {
        if (!this.props.isAdmin) return;
        return (
            <View row spaceBetween center padding={8}>
                <Text h2 bold color={colors.lightestGray}>{strings.menu_view_as_user}</Text>
                <Toggle
                    defaultChecked={this.props.viewAsUser}
                    onChange={() => this.props.setViewAsUser(!this.props.viewAsUser)} />
            </View>
        );
    }

}

const mapStateToProps = state => {
    return {
        name: state.user.name,
        phone: state.user.phone,
        email: state.user.email,
        isAdmin: state.user.isAdmin,
        viewAsUser: state.user.viewAsUser,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setViewAsUser: viewAsUser => dispatch(setViewAsUserAction(viewAsUser))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuContainer);


