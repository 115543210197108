
export function toPointsLabel(points) {
    if (!parseFloat(points)) points = 0;
    let pointsFormatted = formatMoney(points);
    if (!(points % 1)) pointsFormatted = pointsFormatted.replace(",00", "");
    return `${pointsFormatted}pts`;
}

export function toPointsLabelWithoutPts(points) {
    if (!parseFloat(points)) points = 0;
    let pointsFormatted = formatMoney(points);
    if (!(points % 1)) pointsFormatted = pointsFormatted.replace(",00", "");
    return `${pointsFormatted}`;
}

export function clearPointsLabel(pointsLabel) {
    if (!pointsLabel) return null;
    const points = pointsLabel
        .replace(".", "")
        .replace(",", ".")
        .replace("pts", "");
    return parseFloat(points) || null;
}

export function getThousands(points) {
    if (!parseFloat(points)) return null;
    if (points < 1000) return points;
    return Math.floor(points / 1000);
}

export function formatPrice(price) {
    if (!parseFloat(price)) price = 0;
    return `R$ ${formatMoney(price)}`;
}

export function formatUnitPrice(price, packSize) {
    price = parseFloat(price);
    packSize = parseFloat(packSize);
    if (price && packSize) return `R$ ${formatMoney(price / packSize)} un`;
    return null;
}

export function formatDiscountPercent(price, discount) {
    if (!parseFloat(price)) return null;
    if (!parseFloat(discount)) return null;
    const percent = discount / price * 100;
    return `- ${percent.toFixed(1)} %`.replace(".", ",");
}

// Copied from https://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-dollars-currency-string-in-javascript
function formatMoney(n1, c1, d1, t1) {
    var n = parseFloat(n1),
        c = isNaN(c1 = Math.abs(c1)) ? 2 : c1,
        d = d1 === undefined ? "," : d1,
        t = t1 === undefined ? "." : t1,
        s = n < 0 ? "-" : "",
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
        j1 = i.length,
        j = j1 > 3 ? j1 % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
}