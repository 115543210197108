
const colors = {
    
    lightestBlue: '#424AE0',
    lightBlue: '#83a6b8',
    blue: '#3a5c68',
    darkBlue: '#082934',
    darkestBlue: '#000073',

    lightYellow: "#B8AF83",
    lightGreen: '#E5FCF6',
    lightRed: "#FCE5E5",

    selectedGreen: 'rgba(200, 250, 200, 0.95)',
    selectedRed: 'rgba(250, 200, 200, 0.95)',
    selectedBlue: 'rgba(200, 200, 250, 0.95)',

    unselectedGreen: 'rgba(200, 250, 200, 0.45)',
    unselectedRed: 'rgba(250, 200, 200, 0.45)',
    unselectedBlue: 'rgba(200, 200, 250, 0.45)',

    deltaGreen: "#0F6E54",
    deltaRed: "#FF0058",

    deltaGreenBackground: "#E5FCF6",
    deltaRedBackground: '#FFE6E6',

    callBackgroundPurchase: '#9999DD',
    callBackgroundSale: '#DD9999',

    saleBorder: '#FF0058',
    purchaseBorder: '#5562FC',

    callAccentPurchase: '#9999FF',
    callAccentSale: '#FF9999',

    yellow: '#F9E400',
    orange: '#FF851B',
    red: '#721C24',

    white: '#FFFFFF',
    black: "#000000",
    input: 'rgba(255, 255, 255, 0.75)',

    lightestGray: '#FAFAFA',
    lighterGray: '#F0F0F0',
    lightGray: '#F5F5F5',
    gray: '#DDDDDD',
    darkishGray: '#AAAAAA',
    darkisherGray: '#BBBBBB',
    darkGray: '#666666',
    darkestGray: "#333333"
    
}

export default colors;
