
import firebase from 'model/firebase/firebase';
import axios from 'axios';
import { toPointsLabel } from 'model/common/money';
import { parseCall } from './call';
import strings from 'assets/strings';
import { API_URL, ENDPOINT_PUSH } from 'model/common/constants';

export function requestPermission() {
    try {

        if (window.location.href.includes("localhost")) return;

        const messaging = firebase.messaging();
        messaging.usePublicVapidKey("BPsKVmh8BZqoJiXK2dtUYlcZ5vCk25U-k0VW17gAoyy0XRbWQbiWCrHL6PD1DbrgwvmuZxQZjvZCPj5X8dYAg8M");
        Notification.requestPermission();

    } catch(error) {
        console.error(error);
    }
}

export function monitorCalls(id, isAdmin) {
    try {

        if (window.location.href.includes("localhost")) return;

        const messaging = firebase.messaging();

        messaging.getToken().then((token) => storeToken(id, token, "web", isAdmin)).catch((err) => {
            console.log('Unable to Store Token', err);
        });

        messaging.onTokenRefresh(() => {
            messaging.getToken().then((token) => storeToken(id, token, "web", isAdmin)).catch((err) => {
                console.log('Unable to Store Token', err);
            });
        });

    } catch(error) {
        console.error(error);
    }
}

export function notifyUpdate(call, updated, wasDeleted) {

    call = parseCall(call.id, call);

    if (!call || !call.start || !call.out || !call.stop || !call.operation) return;
    if (!call.startLabel || !call.outLabel || !call.stopLabel || !call.operationLabel) {
        call.operationLabel = call.operation === "purchase" ? strings.trade_operation_purchase : strings.trade_operation_sale;
        call.startLabel = toPointsLabel(call.start);
        call.outLabel = toPointsLabel(call.out);
        call.stopLabel = toPointsLabel(call.stop);
    }

    const result = wasDeleted ? "deleted" : call.isGain ? "gain" : call.isLoss ? "loss" : call.isActive ? "active" : "inactive";

    return sendPush(call.operationLabel, call.startLabel, call.outLabel, call.stopLabel, updated, result);

}

export function storeToken(id, token, platform, isAdmin) {

    const requestUrl = API_URL + ENDPOINT_PUSH;
    const requestData = {
        id: id || null,
        token: token || null,
        platform: platform || null,
        isAdmin: isAdmin || null
    };

    console.log("Storing Token: ", requestData);
    
    return axios({
        method: 'post',
        data: requestData,
        url: requestUrl
    }).then(res => {
        const response = res.data;
        console.log("Store Token Response: ", response);
        return Promise.resolve();
    });

}

export function sendPush(operation, start, out, stop, updated, result) {

    const requestUrl = API_URL + ENDPOINT_PUSH;
    const requestData = {
        operation: operation || null,
        start: start || null,
        out: out || null,
        stop: stop || null,
        updated: updated || null,
        result: result || null
    };

    console.log(`Sending Push: `, requestData);

    return axios({
        method: 'post',
        data: requestData,
        url: requestUrl
    }).then(res => {
        const response = res.data;
        console.log("Send Push Response: ", response);
        return Promise.resolve();
    });

}