
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { View, Text, Image } from 'view/factory';

import { monitorTrade } from 'model/firebase';
import { getCallsTotals } from 'model/common/calls';
import { setCallReadAction } from 'controller/actions/tradeAction';

import strings from 'assets/strings';
import colors from 'assets/colors';
import { fullWidth } from 'assets/dimensions';

class TradeContainer extends React.Component {

    componentDidMount() {
        monitorTrade();
    }

    componentDidUpdate() {
        if (this.startOfCalls) {
            this.startOfCalls.scrollIntoView({ behavior: "instant" });
        }
    }

    render() {
        if (!this.props.calls.length) return this.renderEmpty();
        return (
            <View page height={'100%'} color={colors.white}>
                {this.renderDailyTotals()}
                {this.renderAdminActiveNotice()}
                {this.renderList()}
            </View>
        );
    }

    renderDailyTotals() {
        const { total, totalGainPending } = getCallsTotals(this.props.calls);
        return (
            <View fixed top width={fullWidth} center spaceAround row>
                <View flex pHorizontal={8} pTop={6} pBottom={8} color={total >= 0 ? colors.lightGreen : colors.lightRed}>
                    <Text bold align={'center'} h2 color={colors.darkBlue}>{strings.trade_total(total)}</Text>
                    <Text mTop={2} align={'center'} caption color={colors.darkBlue}>{strings.trade_result_current}</Text>
                </View>
                <View flex pHorizontal={8} pTop={6} pBottom={8} color={colors.lightGray}>
                    <Text bold align={'center'} h2 color={colors.darkBlue}>{strings.trade_total(totalGainPending)}</Text>
                    <Text mTop={2} align={'center'} caption color={colors.darkBlue}>{strings.trade_result_pending}</Text>
                </View>
            </View>
        );
    }

    renderAdminActiveNotice() {
        if (!this.props.isAdminActive) return;
        return (
            <View fixed top={42} width={fullWidth} pVertical={8} center height={20} color={colors.white}>
                <Text body color={colors.darkBlue}>{strings.admin_online}</Text>
            </View>
        );
    }

    renderEmpty() {
        return (
            <View page center>
                <Text margin={64} align={'center'} h2 color={colors.lightestGray}>
                    {this.props.loading ? strings.loading_trade : strings.empty_trade}
                </Text>
                {this.renderAddButton()}
            </View>
        );
    }

    renderList() {
        return (
            <View padding={12} mTop={68}>
                <View absolute top ref={element => this.startOfCalls = element} />
                {this.props.calls.map(call => this.renderCall(call))}
                {this.renderAddButton()}
            </View>
        );
    }

    renderAddButton() {
        if (!this.props.isAdmin) return null;
        return <View onClick={() => this.editCall()}
            circle={48} color={colors.darkestBlue} center
            fixed bottom={76} right={16}>
            <Image size={32} src={require("assets/ic_add_white.png")} />
        </View>
    }

    renderCall(call) {
        if (call.isInactiveDivider) return this.renderInactiveDivider();
        return (
            <View key={call.id} onClick={() => this.callClicked(call)}
                activeOpacity={0.8} row
                shadow color={call.isActive ? colors.white : colors.lighterGray}
                border={call.isActive ? { width: 1, color: call.isSale ? colors.saleBorder : colors.purchaseBorder } : null}
                borderLeft={!call.isActive ? { width: 1, color: call.isSale ? colors.saleBorder : colors.purchaseBorder } : null}
                mBottom={8} radius={8}
            >

                <View
                    flex={1} radiusLeft={8}
                    color={call.isSale ? colors.saleBorder : colors.purchaseBorder}
                />

                <View flex={39} padding={16}>

                    <View row centerVertical spaceBetween mBottom={12}>
                        <Text h1 bold color={call.isSale ? colors.saleBorder : colors.purchaseBorder}>{call.operationLabel}</Text>
                        {this.renderNewLabel(call)}
                        {this.renderInactiveLabel(call)}
                    </View>

                    <View row centerHorizontal spaceAround>

                        <View alignStart centerVertical>
                            <View radius={4} pVertical={4} pHorizontal={4} color={call.isActive || call.isLoss ? colors.deltaRedBackground : colors.lighterGray}>
                                <Text bold caption color={call.isActive || call.isLoss ? colors.deltaRed : colors.darkestGray} align={'center'}>{call.stopDeltaLabel}</Text>
                            </View>
                            <Text mVertical={4} h2 bold color={colors.darkestGray} align={'center'}>{call.stopLabel}</Text>
                            <Text caption color={colors.darkishGray} align={'center'}>{strings.trade_stop}</Text>
                        </View>

                        <View center mHorizontal={16}>
                            <View radius={4} pVertical={4} pHorizontal={4} color={call.isActive ? colors.white : colors.lighterGray}>
                                <Text bold caption color={call.isActive ? colors.white : colors.lighterGray} align={'center'}>---</Text>
                            </View>
                            <Text mVertical={4} h2 bold color={colors.darkestGray} align={'center'}>{call.startLabel}</Text>
                            <Text caption color={colors.darkishGray} align={'center'}>{strings.trade_start}</Text>
                        </View>

                        <View alignEnd centerVertical>
                            <View radius={4} pVertical={4} pHorizontal={4} color={call.isActive || call.isGain ? colors.deltaGreenBackground : colors.lighterGray}>
                                <Text bold caption color={call.isActive || call.isGain ? colors.deltaGreen : colors.darkestGray} align={'center'}>{call.outDeltaLabel}</Text>
                            </View>
                            <Text mVertical={4} h2 bold color={colors.darkestGray} align={'center'}>{call.outLabel}</Text>
                            <Text caption color={colors.darkishGray} align={'center'}>{strings.trade_out}</Text>
                        </View>

                    </View>

                    <View row centerVertical spaceBetween mTop={12}>
                        <View />
                        <Text color={colors.darkishGray} caption>{call.updatedLabel}</Text>
                    </View>

                </View>

            </View>
        );
    }

    renderNewLabel(call) {
        if (!call.isActive) return null;
        if (!call.isNew || this.props.isAdmin) return <View height={30} />;
        return (
            <View mRight={-4} height={30} radius={12} pLeft={8} pRight={8} color={colors.orange} center>
                <Text align={'center'} bold caption color={colors.white}>{strings.trade_new}</Text>
            </View>
        );
    }

    renderInactiveLabel(call) {
        if (call.isActive) return null;
        return (
            <View mRight={-4} height={30} radius={12} pLeft={8} pRight={8} center
                color={call.isGain ? colors.deltaGreenBackground : call.isLoss ? colors.deltaRedBackground : colors.darkisherGray}>
                <Text align={'center'} bold body
                    color={call.isGain ? colors.deltaGreen : call.isLoss ? colors.deltaRed : colors.darkestGray}>
                    {call.isGain ? strings.trade_gain : call.isLoss ? strings.trade_loss : strings.trade_inactive}
                </Text>
            </View>
        );
    }

    renderInactiveDivider() {
        return (
            <View row center mVertical={8}>
                <View height={1} flex color={colors.darkGray} />
                <Text mHorizontal={16} color={colors.darkGray} h3>{strings.trade_inactive_divider}</Text>
                <View height={1} flex color={colors.darkGray} />
            </View>
        );
    }

    backgroundColor(call) {
        if (!call.isActive) return colors.darkisherGray;
        if (call.isPurchase) return colors.callBackgroundPurchase;
        return colors.callBackgroundSale;
    }

    editCall(call) {
        this.props.history.push({
            pathname: '/call',
            state: { call: call || {} }
        });
    }

    callClicked(call) {
        if (this.props.isAdmin) return this.editCall(call);
        this.props.setCallRead(call.id);
    }

}

function mapStateToProps(state) {
    return {
        calls: state.trade.calls,
        loading: !state.trade.loaded,
        isAdmin: state.user.isAdmin && !state.user.viewAsUser,
        isAdminActive: state.user.isAdminActive
    }
}

function mapDispatchToProps(dispatch) {
    return ({
        setCallRead: callId => dispatch(setCallReadAction(callId))
    });
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TradeContainer));