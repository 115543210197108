
import firebase from 'model/firebase/firebase';
import moment from 'moment';
import { chatUpdatedAction, chatFetchAction } from 'controller/actions/chatAction';
import { store } from 'controller/Store';
import strings from 'assets/strings';

export function monitorChat() {
    store.dispatch(chatFetchAction());
    firebase.database().ref("chat").on("value", snapshot => {
        const messages = snapshot.val() || {};
        const list = Object.keys(messages).map(key => parseMessage(key, messages[key]));
        const sorted = list.sort((m1, m2) => m1.created - m2.created);
        store.dispatch(chatUpdatedAction(sorted));
    });
}

function parseMessage(id, message) {

    message.id = id;

    const currentDate = moment();
    const createdDate = moment(message.created);
    const howLongAgoDays = currentDate.diff(createdDate, 'days');
    const howLongAgoHours = currentDate.diff(createdDate, 'hours');
    const howLongAgoMinutes = currentDate.diff(createdDate, 'minutes');

    if (howLongAgoHours >= 24) message.createdLabel = strings.chat_sent(strings.date_diff_days(howLongAgoDays));
    else if (howLongAgoMinutes <= 1) message.createdLabel = strings.chat_sent(strings.date_diff_less_minute);
    else if (howLongAgoMinutes < 60) message.createdLabel = strings.chat_sent(strings.date_diff_minutes(howLongAgoMinutes));
    else if (howLongAgoHours === 1) message.createdLabel = strings.chat_sent(strings.date_diff_hour);
    else message.createdLabel = strings.chat_sent(strings.date_diff_hours(howLongAgoHours));

    return message;

}

export function sendMessage(messageId, message) {

    if (messageId) {
        firebase.database().ref(`chat/${messageId}`).update({ text: message, updated: moment.now() });
        return;
    }

    firebase.database().ref("chat").push({ text: message, created: moment.now(), updated: moment.now() });

}

export function removeMessage(messageId) {
    firebase.database().ref(`chat/${messageId}`).remove();
}
