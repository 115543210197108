
import { auth, database } from 'model/firebase/firebase';
import axios from 'axios';

import { store } from 'controller/Store';
import { setUserAction, setAdminActiveAction } from 'controller/actions/userAction';
import { API_URL, ENDPOINT_USER } from 'model/common/constants';

export function monitorUser() {
    auth.onAuthStateChanged(user => {
        if (!user) return store.dispatch(setUserAction({}));
        fetchUser(user.uid, user.phoneNumber).then(user => {
            if (user.isAdmin) setLastAdminActive();
            store.dispatch(setUserAction(user));
        });
    });
}

export function fetchUser(id, phone, email) {

    const requestUrl = API_URL + ENDPOINT_USER;
    const requestData = {
        id: id || null,
        phone: phone ? encodeURIComponent(phone) : null,
        email: email || null
    };

    return axios({
        method: 'get',
        params: requestData,
        url: requestUrl
    }).then(res => {
        const response = res.data;
        if (response.error) return Promise.resolve({ id: id });
        return Promise.resolve(response);
    });

}

export function createUser(name, email) {

    const requestUrl = API_URL + ENDPOINT_USER;
    const requestData = {
        id: auth.currentUser ? auth.currentUser.uid : null,
        phone: auth.currentUser ? auth.currentUser.phoneNumber : null,
        name: name || null,
        email: email || null
    };

    return axios({
        method: 'post',
        data: requestData,
        url: requestUrl
    }).then(res => {
        const response = res.data;
        if (response.error) return Promise.reject(response.error);
        store.dispatch(setUserAction(response));
        return Promise.resolve(response);
    });

}

export function setLastAdminActive() {
    return database.ref('info/lastAdminActive').set(Date.now());
}

export function checkAdminActive() {
    return database.ref('info/lastAdminActive')
        .once('value')
        .then(snapshot => {
            const lastActiveTime = snapshot.val();
            const lastActiveDelta = Date.now() - lastActiveTime;
            const fiveMinutes = 1000 * 60 * 5;
            const isAdminActive = lastActiveDelta < fiveMinutes;
            store.dispatch(setAdminActiveAction(isAdminActive));
            return Promise.resolve(isAdminActive);
        });
}