
import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import chat from 'controller/reducers/chatReducer';
import trade from 'controller/reducers/tradeReducer';
import user from 'controller/reducers/userReducer';

const appReducer = combineReducers({
    trade,
    chat,
    user
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
}

export default persistReducer({
    key: 'root',
    storage: storage,
    whitelist: ['trade', 'chat', 'user']
}, rootReducer);
