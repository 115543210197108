
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { View, Text, Input } from 'view/factory';

import { formatPhoneNumber } from 'react-phone-number-input';
import { showReCaptcha, sendSMS, validateCode } from 'model/auth';
import { createUser } from 'model/firebase/user';

import strings from 'assets/strings';
import colors from 'assets/colors';

class LoginContainer extends React.Component {

    state = {

        reCaptcha: null,

        phone: '',
        phoneLoading: false,
        phoneSuccess: false,

        code: '',
        codeLoading: false,
        codeError: false,

        name: '',
        email: '',
        infoLoading: false

    }

    componentDidMount() {
        this.showReCaptcha();
    }

    componentWillReceiveProps(props) {
        if (props.isUserValid) return this.props.history.replace('/trade');
    }

    render() {
        return (
            <View>
                <View hide={this.props.userId || this.state.reCaptcha}
                    absolute center top={1} bottom={1} left={1} right={1} ref={container => this.recaptchaContainer = container} />
                {this.switchState()}
            </View>
        )
    }

    switchState() {
        if (this.props.userName) return this.pending();
        if (this.props.userId) return this.infoInput();
        if (!this.state.reCaptcha) return this.reCaptchaInput()
        if (this.state.phoneSuccess) return this.codeInput()
        return this.phoneInput();
    }

    reCaptchaInput() {
        return (
            <View page center>

                <Text mHorizontal={32} mBottom={128} h1 align={'center'} color={colors.lightestGray}>{strings.auth_title}</Text>

                <Text mHorizontal={16} mTop={32} h2 align={'center'} color={colors.lightestGray}>{strings.auth_recaptcha_title}</Text>

                <View margin={16}>
                    <Text body mBottom={4} align={'center'} color={colors.lightestGray}>{strings.auth_recaptcha_hint}</Text>
                    <Text caption align={'center'} color={colors.lightestGray}>{strings.auth_recaptcha_loading}</Text>
                </View>

            </View>
        );
    }

    showReCaptcha() {
        showReCaptcha(this.recaptchaContainer).then(result => this.setState({ reCaptcha: result }));
    }

    phoneInput() {
        return (
            <View page centerHorizontal spaceAround >

                <Text mHorizontal={32} h1 align={'center'} color={colors.lightestGray}>{strings.auth_title}</Text>

                <View center>

                    <Text margin={4} h2 align={'center'} color={colors.lightestGray}>{strings.auth_phone_title}</Text>

                    <View margin={16} color={colors.white} radius={12} padding={12}>
                        <PhoneInput
                            country="BR"
                            placeholder={strings.auth_phone_placeholder}
                            value={this.state.phone}
                            onChange={phone => this.setState({ phone })} />
                    </View>

                    <View touchable shadow center width={'75%'} minHeight={40} radius={12}
                        color={this.state.phoneLoading ? colors.lightGray : colors.lightestGray}
                        onClick={() => this.requestSMS()}
                    >
                        <Text h1 align={'center'} color={colors.darkestGray}>
                            {this.state.phoneLoading ? strings.auth_phone_action_loading : strings.auth_phone_action}
                        </Text>
                    </View>

                    <Text margin={16} body align={'center'} color={colors.lightestGray}>{strings.auth_phone_hint}</Text>

                </View>

            </View>
        )
    }

    requestSMS() {
        if (this.state.phoneLoading) return;
        this.setState({ phoneLoading: true });
        sendSMS(this.state.phone)
            .then(result => this.setState({ phoneSuccess: result }))
            .catch(() => this.setState({ phoneLoading: false }));
    }

    codeInput() {
        return (
            <View page centerHorizontal spaceAround >

                <Text mHorizontal={32} h1 align={'center'} color={colors.lightestGray}>{strings.auth_title}</Text>

                <View center>

                    <Text margin={4} h2 align={'center'} color={colors.lightestGray}>{strings.auth_code_title}</Text>

                    <View margin={16}>
                        <Input
                            style={{ textAlign: 'center' }}
                            type={'tel'}
                            maxLength={6}
                            value={this.state.code}
                            onChange={event => this.setState({ code: event.target.value })}
                        />
                    </View>

                    <View touchable shadow center width={'75%'} minHeight={40} radius={12}
                        color={this.state.codeLoading ? colors.lightGray : colors.lightestGray}
                        onClick={() => this.validateCode()}
                    >
                        <Text h1 align={'center'} color={colors.darkestGray}>
                            {this.state.codeLoading ? strings.auth_code_action_loading : strings.auth_code_action}
                        </Text>
                    </View>

                    {this.state.codeError ? <Text mTop={16} mHorizontal={16} body align={'center'} color={colors.selectedRed}>{strings.auth_code_error}</Text> : null}
                    <Text mTop={this.state.codeError ? 8 : 16} mHorizontal={16} body align={'center'} color={colors.lightestGray}>{strings.auth_code_hint(formatPhoneNumber(this.state.phone))}</Text>

                </View>

            </View>
        );
    }

    validateCode() {
        if (this.state.codeLoading) return;
        this.setState({ codeLoading: true, codeError: false });
        validateCode(this.state.code)
            .catch(() => this.setState({ codeLoading: false, codeError: true }));
    }

    infoInput() {
        return (
            <View page centerHorizontal spaceAround >

                <Text mHorizontal={32} h1 align={'center'} color={colors.lightestGray}>{strings.auth_title}</Text>

                <View center>

                    <Text margin={4} h2 align={'center'} color={colors.lightestGray}>{strings.auth_info_title}</Text>

                    <View width={'100%'} mTop={16} mBottom={4}>
                        <Input
                            placeholder={strings.auth_info_name_placeholder}
                            style={{ textAlign: 'center' }}
                            type={'text'}
                            autoCapitalize={'words'}
                            value={this.state.name}
                            onChange={event => this.setState({ name: event.target.value })}
                        />
                    </View>

                    <View width={'100%'} mBottom={16} mTop={4}>
                        <Input
                            placeholder={strings.auth_info_email_placeholder}
                            style={{ textAlign: 'center' }}
                            type={'email'}
                            autoCapitalize={'none'}
                            value={this.state.email}
                            onChange={event => this.setState({ email: event.target.value })}
                        />
                    </View>

                    <View touchable shadow center width={'75%'} minHeight={40} radius={12}
                        color={this.state.codeLoading ? colors.lightGray : colors.lightestGray}
                        onClick={() => this.createUser()}
                    >
                        <Text h1 align={'center'} color={colors.darkestGray}>
                            {this.state.infoLoading ? strings.auth_info_action_loading : strings.auth_info_action}
                        </Text>
                    </View>

                    <Text margin={16} body align={'center'} color={colors.lightestGray}>{strings.auth_info_hint}</Text>

                </View>

            </View>
        );
    }

    createUser() {
        if (!this.state.name || !this.state.email) return;
        if (this.state.infoLoading) return;
        this.setState({ infoLoading: true });
        createUser(this.state.name, this.state.email);
    }

    pending() {
        return (
            <View page center >
                <Text mBottom={32} mHorizontal={48} h1 align={'center'} color={colors.lightestGray}>{strings.auth_pending_title}</Text>
                <Text mHorizontal={48} h2 align={'center'} color={colors.lightestGray}>{strings.auth_pending_hint}</Text>
            </View>
        );
    }

}

function mapStateToProps(state) {
    return {
        userId: state.user.id,
        userName: state.user.name,
        isUserValid: state.user.isValid
    }
}

function mapDispatchToProps(dispatch) {
    return ({

    });
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginContainer));